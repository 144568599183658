import { PLATFORM_API_URL } from '../constants/api';

const getAPIUrl = wixCodeApi => {
  if (wixCodeApi.window.rendering.env !== 'backend') {
    return PLATFORM_API_URL;
  }

  const baseUrl = wixCodeApi.location.baseUrl;
  const origin = !baseUrl.includes('wixsite.com') ? baseUrl : baseUrl.substring(0, baseUrl.lastIndexOf('/'));

  return `${origin}${PLATFORM_API_URL}`;
};

export default {
  getAPIUrl,
};
